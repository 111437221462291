import React, { Component } from "react";
import { Helmet } from "react-helmet";
import he from "he";
import data from "../../../.brew-cache/data.json";
import themeOptions from "../../../.brew-cache/theme.json";
import Apple from "../../images/favicon/apple-touch-icon.png";
import Fav48 from "../../images/favicon/favicon-48x48.png";
import favSvg from "../../images/favicon/favicon.svg";

import { replaceMasks } from "../../services/masks";

class Meta extends Component {
  defaultImage = themeOptions.ThemeOptions.defaultImages.schemaAndShareImage.sourceUrl.replace(
    process.env.GATSBY_ADMIN_URL,
    process.env.GATSBY_FRONTEND_URL
  );

  getSchema() {
    const schemaOrgJSONLD = [
      {
        "@context": "http://schema.org",
        "@type": "LocalBusiness",
        name: data.name,
        legalName: data.name,
        openingHours: data.opening_hours,
        geo: {
          "@type": "GeoCoordinates",
          latitude: data.latitude,
          longitude: data.longitude,
        },
        description: "",
        slogan: "",
        address: {
          "@type": "PostalAddress",
          addressLocality: data.town,
          addressRegion: data.region,
          postalCode: data.postcode,
          streetAddress: data.address1,
        },
        telephone: data.telephone,
        email: data.email,
        image: this.defaultImage,
        url: data.url,
      },
    ];

    // Add any custom schema objects being passed through from the page
    if (this.props.meta.schema) {
      this.props.meta.schema.map((item) => {
        return schemaOrgJSONLD.push(item);
      });
    }
    return JSON.stringify(schemaOrgJSONLD);
  }
  render() {
    const meta = replaceMasks(this.props.meta);

    return (
      <Helmet
        encodeSpecialCharacters={false}
        htmlAttributes={{ lang: meta.locale ? meta.locale : "en" }}
      >
        <title>
          {meta.metaTitle ? he.decode(meta.metaTitle) : this.props.title}
        </title>
        <html lang="en" />
        <meta name="description" content={meta.metaDescription} />

        {meta.canonical !== undefined ? (
          <link rel="canonical" href={data.url + "/tyde" + meta.canonical} />
        ) : null}

        <meta name="docsearch:version" content="2.0" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
        />

        {/* Schema */}
        <script type="application/ld+json">{this.getSchema(data.url)}</script>

        {/* OpenGraph */}
        {meta.ogTitle ? (
          <meta property="og:url" content={data.url + this.props.path} />
        ) : null}
        {meta.ogTitle ? <meta property="og:type" content="website" /> : null}
        {meta.ogTitle ? <meta property="og:locale" content="en" /> : null}
        {meta.ogTitle ? (
          <meta property="og:site_name" content={data.name} />
        ) : null}
        {meta.ogTitle ? (
          <meta property="og:title" content={meta.ogTitle} />
        ) : null}
        {meta.ogDescription ? (
          <meta property="og:description" content={meta.ogDescription} />
        ) : null}
        {meta.ogImage ? (
          <meta property="og:image" content={data.url + meta.ogImage} />
        ) : (
          <meta property="og:image" content={this.defaultImage} />
        )}

        {/* Twitter Card */}
        {meta.twitterTitle ? (
          <meta name="twitter:site" content={data.twitter_handle} />
        ) : null}
        {meta.twitterTitle ? (
          <meta name="twitter:title" content={meta.twitterTitle} />
        ) : null}
        {meta.twitterTitle ? (
          <meta name="twitter:description" content={meta.twitterDescription} />
        ) : null}
        {meta.twitterTitle ? (
          <meta name="twitter:card" content={meta.twitterCardType} />
        ) : null}
        {meta.twitterImage ? (
          <meta name="twitter:image" content={data.url + meta.twitterImage} />
        ) : (
          <meta name="twitter:image" content={this.defaultImage} />
        )}

        <link rel="icon" type="image/png" href={Fav48} sizes="48x48" />
        <link rel="icon" type="image/svg+xml" href={favSvg} />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href={Apple} />
      </Helmet>
    );
  }
}

export default Meta;
